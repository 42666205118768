export const GQL_ENDPOINT = window.location.hostname.includes("iprefer.com") ? "https://iprefer-api.devlab.zone/api/graphql" : window.location.hostname.includes("webflow.io") ? "https://iprefer-dev-api.devlab.zone/api/graphql" : window.location.hostname.includes("devlab.zone") ? "https://iprefer-staging-api.devlab.zone/api/graphql" : "http://localhost:4040/api/graphql"

export const S3_BASE_URL = window.location.hostname.includes("iprefer.com") ? "https://iprefer-prod.s3.amazonaws.com/" : window.location.hostname.includes("devlab.zone") ? "https://iprefer-dev.s3.amazonaws.com/" : "https://iprefer-dev.s3.amazonaws.com/";

export const ADMIN_PATHS = {
    signIn: "/admin/sign-in",
    adminVerification: "/admin/verification",
    forgotPassword: "/admin/forgot-password",
    resetPassword: "/admin/reset-password",
    dashboard: "/admin/overview",
    settings: "/admin/settings",
    userList: "/admin/user/list",
    userDetails: "/admin/user/view",
    hotelList: "/admin/hotel/list",
    hotelDetail: "/admin/hotel/view",
    courseList: "/admin/course/list",
    courseDetail: "/admin/course/view",
    googleAnalytics: "/admin/analytics/ga"
};

export const PUBLIC_PATHS = {
    landingPage: "/",
    unsubscribePage: "/unsubscribe",
    oldHome: "/old-home",
    signIn: "/user/sign-in",
    signUp: "/user/sign-up",
    userVerification: "/user/verification",
    dashboard: "/user/dashboard",
    forgotPassword: "/user/forgot-password",
    resetPassword: "/user/reset-password",
    settings: "/user/settings",
    courseList: "/user/courses",
    courseDetail: "/user/courses/(.*)",
    lessonDetail: "/lessons/(.*)",
    lessonDetailRoute: "/lessons",
    courseHighlightRoute: "/course-highlights",
    courseHighlight: "/course-highlights/(.*)",
    hotelList: "/user/hotels",
    hotelDetail: "/hotels/(.*)",
    pioneerList: "/user/pioneers",
    pioneerDetail: "/pioneers/(.*)",
    newsList: "/user/news",
    resourceList: "/user/resources",
    userSearchResults: "/user/search-results",
    hotelsDetailRoute: "/hotels",
    courseDetailRoute: "/user/courses",
    pioneerDetailRoute: "/pioneers",
    newsDetailRoute: "/news",
    hotelSearchRoute: "/user/search-hotels",
    courseSearchRoute: "/user/search-courses",
    pioneerSearchRoute: "/user/search-pioneers",
    newsSearchRoute: "/user/search-news",
    aboutTraining: "/user/about-this-training",
    backdoor: "/user/dev-backdoor"
};

const ISSUER_VALUE = window.location.hostname.includes("iprefer.com") ? "mogul" : window.location.hostname.includes("devlab.zone") ? "mogulstage": "mogul";

export const SSO_AUTH_URL = `https://preferrednet.net/login/?ReturnPath=/Handlers/SSO/SAML2.ashx?Issuer=${ISSUER_VALUE}`;

const WORDS_PER_MINUTE = 200;