import { WFComponent, WFFormComponent } from "@xatom/core";
import { publicQL } from "../../graphql";
import { PublicUploadAvatarDocument, UserMeDocument } from "../../graphql/graphql";
import { S3_BASE_URL } from "../../config";
import { logoutPublicAuth } from "../../auth/public";
const defaultUsrAvatar = "https://assets-global.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg";
let loaderProgress = 0;

export const userSidebar = () => {
    try {
        setWeglot();
        const userDataReq = publicQL.query(UserMeDocument);
        const avatarChangeReq = publicQL.mutation(PublicUploadAvatarDocument);
        const logoutBtn = new WFComponent(`[xa-type="sidebar-signout-btn"]`);
        logoutBtn.on("click", () => {
            logoutPublicAuth();
        })
        const userAvatarForm = new WFFormComponent<{
            img: File;
        }>(`[xa-type="avatar-upload-form"]`);

        const userAvatarInput = new WFComponent<HTMLInputElement>(`#sidebar-dp-upload`);
        const userImage = new WFComponent(`[xa-type="user-avatar"]`);
        const userImagePlaceholderWrap = new WFComponent(`[xa-type="sidebar-user-placeholder-wrap"]`);
        const userImagePlaceHolder = userImagePlaceholderWrap.getChildAsComponent(`[xa-type="sidebar-user-placeholder"]`);
        const userName = new WFComponent(`[xa-type="user-name"]`);
        const avatarLoaderWrap = new WFComponent(`[xa-type="avatar-loader-wrap"]`);
        const avatarLoader = avatarLoaderWrap.getChildAsComponent(`[xa-type="avatar-loader"]`);

        window.onload = function () {
            const loaderIntervalId = setInterval(() => {
                loaderAnimation(avatarLoader);
            }, 10);
        }

        userDataReq.onLoadingChange((status) => {
            if (status) {
                avatarLoaderWrap.removeCssClass("hide");
            }
        });

        userDataReq.onData((data) => {
            if (!data.userMe.enabled) {
                logoutPublicAuth();
            } else {
                userName.getElement().innerHTML = `${data.userMe.firstName} ${data.userMe.lastName}`;
                userName.removeCssClass("disabled");
                if (data.userMe.avatar) {
                    userImage.setAttribute("src", `${S3_BASE_URL}${data.userMe.avatar}`);
                    userImage.setAttribute("srcset", `${S3_BASE_URL}${data.userMe.avatar}`);
                    userImage.removeCssClass("hide");
                } else {
                    const fullName = `${data.userMe.firstName} ${data.userMe.lastName}`;
                    const initials = getInitials(fullName);
                    const imageColors = ['#F7E5C2', '#F2E5D1', '#EFEFEE', '#F6F6F7', '#D5E2E8', '#FBE1D5'];
                    userImagePlaceHolder.setText(`${initials}`);
                    userImagePlaceholderWrap.getElement().style.backgroundColor = getRandomValueFromArray(imageColors);
                    userImagePlaceholderWrap.removeCssClass("hide");
                    userImage.addCssClass("hide");
                }
                avatarLoaderWrap.addCssClass("hide");
            }
        });
        userDataReq.fetch();

        //Update user Avatar
        userAvatarInput.on("change", () => {
            if (userAvatarForm.getFormData() && userAvatarForm.getFormData().img) {
                const image = userAvatarForm.getFormData().img;
                userImage.addCssClass("hide");
                avatarLoaderWrap.removeCssClass("hide");
                avatarChangeReq.fetch({
                    imageFile: image
                });

                // avatarChangeReq.onLoadingChange((status) => {
                //     if (status) {

                //     }
                // });
            }
        });
        userAvatarForm.onFormSubmit((data) => {
            //do nothing

        });

        avatarChangeReq.onError((err) => {
        })

        avatarChangeReq.onData((data) => {
            userAvatarForm.resetForm();
            userImage.setAttribute("src", `${data.publicUploadAvatar}`);
            userImage.setAttribute("srcset", `${data.publicUploadAvatar}`);
            userImage.removeCssClass("hide");
            avatarLoaderWrap.addCssClass("hide");
        })
    } catch (err) {
        console.log("We think this page doesn't have a sidebar. Check this error - ", err.message);
    }


    function getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    function getRandomValueFromArray(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    function loaderAnimation(loader) {
        loader.getElement().style.background = `conic-gradient(#00B8B4 ${loaderProgress * 3.6}deg, #ededed 0deg)`;
        loaderProgress = (loaderProgress + 1) % 100;
    }
}


const setWeglot = () => {
    if (!Weglot) {
        return;
    }

    const list = document.getElementById("w-dropdown-list-0");
    list.innerHTML = "";
    const toggleFlag = new WFComponent(`[xa-type="toggle-flag"]`);
    const toggleText = new WFComponent(`[xa-type="toggle-text"]`);
    toggleText.setText("");
    let selectedLang = localStorage.getItem("wglang") || "en";
    const changeLanguage = (lang: string) => {
        Weglot.switchTo(lang);
    };

    Weglot.on("languageChanged", (newLang: string) => {
        list.innerHTML = "";
        selectedLang = newLang;
        setLanguages();
    })

    const setLanguages = () => {
        let availableLanguages = ["en", "ja", "es", "fr", "zh"];
        for (let i = 0; i < availableLanguages.length; i++) {
            let lang = availableLanguages[i];
            const a = document.createElement("a");
            a.className = "sidebar_dropdown-link w-inline-block";
            a.tabIndex = 0;
            a.href = "#";
            const img = document.createElement("img");
            img.loading = "lazy";
            img.className = "sidebar_language_flag-img";
            const div = document.createElement("div");
            div.className = "text-size-small";
            if (lang === "en") {
                const imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab415c82498e366421_English.png";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "English"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ja") {
                const imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab49c26cdb95396c78_Japanese.png"
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "日本語"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "es") {
                const imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4abdcd4aaffb4194b35_Spanish.png";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Español"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "fr") {
                const imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab543dbb3f4808551b_French.png";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Français"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "zh") {
                const imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab3c26acb95503cbae_Chinese.png";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "中文 (简体)"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            }
            a.appendChild(img);
            a.appendChild(div);
            list.appendChild(a);
            if (i < availableLanguages.length - 1) {
                const divider = document.createElement('div');
                divider.classList.add("sidebar_dropdown-divider");
                list.appendChild(divider);
            }
        }
        setSelectedLanguage();
    }


    const setSelectedLanguage = () => {
        let imgURL = "", languageText = "";
        if (selectedLang === "en") {
            imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab415c82498e366421_English.png";
            languageText = "English";
        } else if (selectedLang === "ja") {
            imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab49c26cdb95396c78_Japanese.png"
            languageText = "日本語";
        } else if (selectedLang === "es") {
            imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4abdcd4aaffb4194b35_Spanish.png";
            languageText = "Español";
        } else if (selectedLang === "fr") {
            imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab543dbb3f4808551b_French.png";
            languageText = "français";
        } else if (selectedLang === "zh") {
            imgURL = "https://uploads-ssl.webflow.com/6556f4e9bd95ad0ca4739845/6569a4ab3c26acb95503cbae_Chinese.png";
            languageText = "中文 (简体)";
        }
        toggleText.setText(languageText);
        toggleFlag.setAttribute("src", imgURL);
        toggleFlag.setAttribute("srcSet", imgURL);
    }

    setLanguages();
}
