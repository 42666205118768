import { WFComponent } from "@xatom/core";

export const skeletonLoader = () => {

    return {
        show: (wrapper?) => {
            const skeletonElements = (wrapper?.getElement() || document).querySelectorAll('[xa-skeleton-loader="true"]');
            skeletonElements.forEach(element => {
                const existingSkeleton = element.querySelector('.skeleton-loader');
                if (!existingSkeleton) {
                    const skeletonDiv = document.createElement('div');
                    skeletonDiv.classList.add("skeleton-loader");
                    const htmlElement = element as HTMLElement;
                    htmlElement.style.position = 'relative';
                    htmlElement.appendChild(skeletonDiv);
                }
            });
        },

        hide: (wrapper?) => {
            const skeletonElements = (wrapper?.getElement() || document).querySelectorAll('[xa-skeleton-loader="true"]');
            skeletonElements.forEach(element => {
                const skeletonDiv =  element.querySelector('.skeleton-loader');
                const htmlElement = element as HTMLElement;

                if (skeletonDiv) {
                    htmlElement.removeChild(skeletonDiv);
                }
            });
        }
    }
}

export const scriptLoader = () => {
    return {
        hide: () => {
            const loader = new WFComponent(`body`);
            const loaderElts = loader.getChildAsComponents(`.loader`);

            loaderElts.forEach(element => {
                element.removeCssClass("loader");
            })
        }
    }
}