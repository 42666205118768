import { WFRoute, navigate } from "@xatom/core";
import { PUBLIC_PATHS } from "../../config";
import { publicMiddleware } from "../../auth/public";
import { userSidebar } from "../../modules/public/userSidebar";
import { skeletonLoader } from "client-utils";
import { unsubscribePage } from "../../modules/public/unsubscribe";

const publicRoutes = () => {

    userSidebar();
    skeletonLoader().show();
    new WFRoute(PUBLIC_PATHS.landingPage)
        .withMiddleware(publicMiddleware, "NONE", "allow", {
            onError: () => {
                console.log("err");
                navigate({
                    to: PUBLIC_PATHS.dashboard,
                    type: "replace",
                });
            },
        })
        .execute((param: any) => import("../../modules/public/landing").then(({ landing }) => { landing(param) }));

    new WFRoute(PUBLIC_PATHS.oldHome)
        .withMiddleware(publicMiddleware, "NONE", "allow", {
            onError: () => {
                console.log("err");
                navigate({
                    to: PUBLIC_PATHS.dashboard,
                    type: "replace",
                });
            },
        })
        .execute((param: any) => import("../../modules/public/landing").then(({ landing }) => { landing(param) }));

    new WFRoute(PUBLIC_PATHS.backdoor)
        .withMiddleware(publicMiddleware, "NONE", "allow", {
            onError: () => {
                console.log("err");
                navigate({
                    to: PUBLIC_PATHS.dashboard,
                    type: "replace",
                });
            },
        })
        .execute((param: any) => import("../../modules/public/devBackdoor").then(({ devSignIn }) => { devSignIn(param) }));

    new WFRoute(PUBLIC_PATHS.signUp).withMiddleware(publicMiddleware, "NONE", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.dashboard,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/userSignUp").then(({ userSignup }) => { userSignup() })
    );


    new WFRoute(PUBLIC_PATHS.forgotPassword).withMiddleware(publicMiddleware, "NONE", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.dashboard,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/forgotPassword").then(({ userForgotPassword }) => { userForgotPassword() })
    );

    new WFRoute(PUBLIC_PATHS.resetPassword).withMiddleware(publicMiddleware, "NONE", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.dashboard,
                type: "replace",
            });
        },
    }).execute(
        (param: any) => import("../../modules/public/resetPassword").then(({ userResetPassword }) => { userResetPassword(param) })
    );

    new WFRoute(PUBLIC_PATHS.userVerification).withMiddleware(publicMiddleware, "NONE", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.dashboard,
                type: "replace",
            });
        },
    }).execute(
        (param: any) => import("../../modules/public/userVerification").then(({ userVerification }) => { userVerification(param) })
    );


    new WFRoute(PUBLIC_PATHS.unsubscribePage).execute(
        unsubscribePage
    );

    new WFRoute(PUBLIC_PATHS.dashboard).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/dashboard").then(({ userDashboard }) => { userDashboard() })
    );

    new WFRoute(PUBLIC_PATHS.settings).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/userSettings").then(({ userSettings }) => { userSettings() })
    );

    new WFRoute(PUBLIC_PATHS.courseHighlight).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/courseHighlights").then(({ courseHighlights }) => { courseHighlights() })
    );

    new WFRoute(PUBLIC_PATHS.lessonDetail).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/lessonDetail").then(({ lessonDetail }) => { lessonDetail() })
    );

    new WFRoute(PUBLIC_PATHS.courseDetail).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/courseDetail").then(({ courseDetail }) => { courseDetail() })
    );

    new WFRoute(PUBLIC_PATHS.courseList).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/courseList").then(({ courseList }) => { courseList() })
    );

    new WFRoute(PUBLIC_PATHS.hotelDetail).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(
        () => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() })
    );

    new WFRoute(PUBLIC_PATHS.hotelList).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.pioneerList).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.pioneerDetail).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.newsList).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.resourceList).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.hotelSearchRoute).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.courseSearchRoute).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.pioneerSearchRoute).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.newsSearchRoute).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    new WFRoute(PUBLIC_PATHS.aboutTraining).withMiddleware(publicMiddleware, "USER", "allow", {
        onError: () => {
            console.log("err");
            navigate({
                to: PUBLIC_PATHS.landingPage,
                type: "replace",
            });
        },
    }).execute(() => import("../../modules/public/blank").then(({ blankPage }) => { blankPage() }));

    // new WFRoute(PUBLIC_PATHS.landingPage)
    //     .withMiddleware(publicMiddleware, "NONE", "allow", {
    //         onError: () => {
    //             console.log("err");
    //             navigate({
    //                 to: PUBLIC_PATHS.dashboard,
    //                 type: "replace",
    //             });
    //         },
    //     })
    //     .execute((param: any) => import("../../modules/public/landing").then(({ landing }) => { landing(param) }));
};

export default publicRoutes;
